<template>
  <div>
    <modal-edit-text
      v-if="showEditText"
      :meetingtypeId="meetingtypeId"
      :showModal="showEditText"
      :onClickCancel="hideModal"
    />

    <ui-section-header :showEdit="true" :editFunction="setShowEditSpaceText">
      <template v-slot:title>Publication text</template>
    </ui-section-header>

    <div class="columns">
      <div
        v-for="(textLanguage, index) in descriptions"
        :key="index"
        class="column"
      >
        <div class="title is-5">
          {{ $t('LanguageLabels.Language' + textLanguage.LanguageId) }}
        </div>

        <div class="textWrapper has-padding">
          <strong>Name:</strong><br />
          {{ textLanguage.Name }}

          <div class="is-divider"></div>

          <strong>Description:</strong><br />
          <div class="content" v-html="textLanguage.Description"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

const ModalEditText = () => import('@/components/Spaces/ModalEditSpaceText')

export default {
  components: {
    'modal-edit-text': ModalEditText,
  },

  props: {
    meetingtypeId: {
      default: 0,
      type: Number,
    },
  },

  data() {
    return {
      showEditText: false,
    }
  },

  computed: {
    ...mapState('spaceStore', ['space']),

    descriptions() {
      if (this.space) {
        return this.space.Descriptions.filter(
          (s) => s.MeetingtypeId === this.meetingtypeId
        )
      }
      return []
    },
  },

  created() {},

  methods: {
    setShowEditSpaceText() {
      this.showEditText = true
    },

    hideModal() {
      this.showEditText = false
    },
  },
}
</script>
